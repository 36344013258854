/deep/.el-dialog {
  max-width: 497px;

  .el-dialog__header {
    padding: 20px 20px 5px;

    img {
      width: 24px;
      cursor: pointer;
    }
  }

  .el-dialog__body {
    padding: 15px 55px 35px;
    text-align: center;
    word-break: normal;

    img {
      width: 114px;
      margin-bottom: 20px;
    }

    .dialog_body {
      p {
        font-size: 20px;
        line-height: 1.5;
        color: $primary-purple;
        margin-bottom: 40px;
      }

      a {
        margin-top: 30px;
        font-size: 16px;
        margin-bottom: 50px;
        color: #90bbea;
      }
    }
  }
}

@media (max-width: 550px) {
  /deep/ .el-dialog .el-dialog__body {
    padding: 24px;

    .dialog_body p {
      font-size: 16px;
    }
  }
}
