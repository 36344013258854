.rebate {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin-bottom: 88px;

  .left {
    width: calc(50% - 52px);
    height: 100%;

    padding: 24px 0 18px;

    /deep/ .el-form {
      .el-form-item {
        margin-bottom: 0;
      }

      .el-input .el-input__inner {
        border-bottom: unset;
      }
    }
  }

  .middle {
    width: 56px;
    height: 56px;
    @include rtl-sass-prop-sd(transform, rotate(180deg));
    margin: auto;
  }

  .right {
    width: calc(50% - 52px);
    height: 100%;
    background-color: $main-b-color;
    padding: 24px 0 18px;
  }

  .left,
  .right {
    display: flex;
    flex-direction: row;

    justify-content: center;

    background-color: $main-b-color;
    border: $container-border;
    border-radius: $container-radius;

    .container {
      padding: 0 24px 18px;
      position: relative;

      .account {
        background-color: #73246b !important;
        border-radius: 25px;
        padding: 0 30px;

        /deep/ .el-input__inner {
          color: $white;
          @include rtl-sass-value(
            background,
            url(~@/assets/uploads/icon_caret_bottom_white.png) no-repeat right center transparent,
            url(~@/assets/uploads/icon_caret_bottom_white.png) no-repeat left center transparent
          );

          &:focus {
            border-bottom: unset !important;
          }
        }
      }

      p {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.42;
        color: $primary-purple;
        text-align: center;
      }

      .form_button {
        text-align: center;
      }
    }

    .container_amount {
      margin-top: 20px;
      margin-bottom: auto;
      p {
        font-size: 32px;
        font-weight: 500;
        line-height: 1.38;
        color: $primary-green;

        &:not(:first-child) {
          font-size: 16px;
        }
      }
    }

    .container_button {
      margin-top: 20px;

      a {
        text-align: center;
        line-height: 1.5;
      }
    }
  }
}

// @media (max-width: 1366px) {
//   .rebate .left .container .el-form {
//     display: contents;
//   }
// }

@media (max-width: 768px) {
  .rebate {
    margin-bottom: 40px;
    flex-direction: column;

    .left,
    .right {
      width: 100%;
      margin-bottom: 20px;
    }

    .middle {
      transform: rotate(90deg);
      margin-bottom: 20px;
    }

    // .left .container .el-form {
    //   display: contents;
    // }
  }
}

@media (max-width: 550px) {
  .rebate {
    margin-bottom: 24px;

    .left,
    .right {
      .container span {
        font-size: 20px;
      }

      .container_bottom {
        .container_amount,
        .container_bottom_right {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
